import { ReactElement } from "react";
import { graphql } from "gatsby";
import * as styles from "./specialOffer.module.scss";
import Helmet from "../../components/Helmet";

interface ISpecialOffer {
    data: GatsbyTypes.SpecialOfferPageQuery;
}

export default function SpecialOffer({ data }: ISpecialOffer): ReactElement {
    return (
        <section className={styles.specialOfferPage}>
            <Helmet title="Special Offer" description={""} />
            <div className="headerContainer">
                <div className="mainBox">
                    <h1>{data.strapi.specialOfferPage?.hero?.base?.heading}</h1>
                </div>
            </div>

            <div className={styles.wrapper}>
                <div className={styles.inner}>
                    <div className={styles.disclaimer} dangerouslySetInnerHTML={{ __html: data.strapi.specialOfferPage?.hero?.base?.paragraph ?? "" }}></div>
                    <div dangerouslySetInnerHTML={{ __html: data.strapi.specialOfferPage?.body ?? "" }}></div>
                </div>
            </div>
        </section>
    );
}

export const query = graphql`
    query SpecialOfferPage {
        strapi {
            specialOfferPage {
                body
                hero {
                    base {
                        heading
                        paragraph
                    }
                }
            }
        }
    }
`;
